import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, EventEmitter, Component, Input, Output, NgModule } from '@angular/core';
import { flatMap, switchMap, retryWhen, tap, concatMap, delay, shareReplay, filter, scan, map, mergeMap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, iif, of, combineLatest } from 'rxjs';
import { EventSourcePolyfill } from 'event-source-polyfill';
import * as i1 from '@aviatar/sso';
import * as i2 from '@angular/common/http';
import { HttpParams, HttpHeaders, HttpRequest, HttpResponse, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import * as i5 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i6 from '@angular-extensions/elements';
import { LazyElementsModule } from '@angular-extensions/elements';
function TaskFormComponent_ng_container_0_ax_lazy_element_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ax-lazy-element", 4);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "async");
    i0.ɵɵlistener("completed", function TaskFormComponent_ng_container_0_ax_lazy_element_1_Template_ax_lazy_element_completed_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onTaskCompleted($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("task", i0.ɵɵpipeBind1(1, 4, ctx_r1.task$))("engineUrl", i0.ɵɵpipeBind1(2, 6, ctx_r1.engineBaseUrl$))("bearerToken$", ctx_r1.bearerToken$)("payload", ctx_r1.payload);
  }
}
function TaskFormComponent_ng_container_0_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵtext(1, " Could not load task form component. ");
    i0.ɵɵelementEnd();
  }
}
function TaskFormComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TaskFormComponent_ng_container_0_ax_lazy_element_1_Template, 3, 8, "ax-lazy-element", 3)(2, TaskFormComponent_ng_container_0_ng_template_2_Template, 2, 0, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const config_r3 = ctx.ngIf;
    const error_r4 = i0.ɵɵreference(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("axLazyElementDynamic", config_r3.tag)("axLazyElementDynamicUrl", config_r3.url)("axLazyElementDynamicErrorTemplate", error_r4);
  }
}
function TaskFormComponent_ng_template_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0, " Loading task form...\n");
  }
}
const PROCESS_MODULE_CONFIG = new InjectionToken('aviatar-process-module-config');
class StreamService {
  constructor(keycloakService) {
    this.keycloakService = keycloakService;
  }
  subscribe(url) {
    const source = new BehaviorSubject(null).pipe(flatMap(() => from(this.keycloakService.getToken())));
    return source.pipe(switchMap(token => {
      const eventSource = new EventSourcePolyfill(`${url}`, {
        withCredentials: false,
        heartbeatTimeout: 20 * 1000,
        // reconnect if within 20 seconds no update was streamed (heartbeat is expected every 10 seconds)
        headers: {
          authorization: 'Bearer ' + token
        }
      });
      return new Observable(observer => {
        eventSource.onmessage = event => {
          const data = JSON.parse(event.data);
          observer.next(data);
        };
        eventSource.onerror = error => {
          eventSource.close();
          observer.error(error);
        };
        return () => eventSource.close();
      });
    }), retryWhen(error => error.pipe(tap(err => console.log('event source stream was closed with error. reopening connection.', err)), concatMap((e, i) => iif(() => i > 10, of(e).pipe(delay(15000)),
    // throttle reconnect attempts after 10 retries to every 15 seconds
    of(e).pipe(delay(500)) // retry every 500ms for the first attempts
    )))));
  }
  static {
    this.ɵfac = function StreamService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || StreamService)(i0.ɵɵinject(i1.KeycloakService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: StreamService,
      factory: StreamService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StreamService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.KeycloakService
  }], null);
})();
let ProcessService$1 = class ProcessService {
  constructor(streamService, processModuleConfig) {
    this.streamService = streamService;
    this.processModuleConfig = processModuleConfig;
    this.sortByCreateTime = tasks => tasks.sort((a, b) => a.createTime.localeCompare(b.createTime));
    this.filterTasks = (task, taskLink) => {
      return taskLink.processDefinitionKeys.indexOf(task.processDefinitionKey) >= 0 && (!!taskLink.businessKey && task.businessKey === taskLink.businessKey || !!taskLink.businessKeyRegex && !!task.businessKey.match(taskLink.businessKeyRegex)) && (!taskLink.taskDefinitionKey || task.taskDefinitionKey === taskLink.taskDefinitionKey);
    };
    this.tasks$ = this.streamService.subscribe(`${processModuleConfig.tasklistApiBaseUrl}/task-stream`).pipe(shareReplay({
      refCount: true
    }));
  }
  /**
   * Get tasks filtered by their business-key and process-definition-key
   */
  getTasks(taskLink) {
    if (!!taskLink.businessKey && !!taskLink.businessKeyRegex) {
      throw Error('Method cannot be called with with businessKey AND businessKeyRegex set.');
    }
    if (!taskLink.businessKey && !taskLink.businessKeyRegex) {
      throw Error('Either businessKey or businessKeyRegex must be set.');
    }
    return this.tasks$.pipe(shareReplay(), filter(task => this.filterTasks(task, taskLink)), scan((linkedTasks, task) => {
      const isTaskInList = this.isTaskInList(linkedTasks, task);
      if (task.deleted && isTaskInList) {
        // remove task
        linkedTasks = linkedTasks.filter(t => t.id !== task.id);
      }
      if (!task.deleted && !isTaskInList) {
        linkedTasks.push(task);
      }
      return linkedTasks;
    }, []), map(this.sortByCreateTime));
  }
  isTaskInList(list, task) {
    return !!list.find(taskInList => taskInList.id === task.id);
  }
  static {
    this.ɵfac = function ProcessService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ProcessService)(i0.ɵɵinject(StreamService), i0.ɵɵinject(PROCESS_MODULE_CONFIG));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ProcessService,
      factory: ProcessService.ɵfac,
      providedIn: 'root'
    });
  }
};
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProcessService$1, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: StreamService
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PROCESS_MODULE_CONFIG]
    }]
  }], null);
})();

/* tslint:disable */
/**
 * Custom parameter codec to correctly handle the plus sign in parameter
 * values. See https://github.com/angular/angular/issues/18261
 */
let ParameterCodec$1 = class ParameterCodec {
  encodeKey(key) {
    return encodeURIComponent(key);
  }
  encodeValue(value) {
    return encodeURIComponent(value);
  }
  decodeKey(key) {
    return decodeURIComponent(key);
  }
  decodeValue(value) {
    return decodeURIComponent(value);
  }
};
const PARAMETER_CODEC$1 = new ParameterCodec$1();
/**
 * Base class for API services
 */
let BaseService$1 = class BaseService {
  constructor(config, http) {
    this.config = config;
    this.http = http;
    this._rootUrl = '';
  }
  /**
   * Returns the root url for API operations. If not set directly in this
   * service, will fallback to ApiConfiguration.rootUrl.
   */
  get rootUrl() {
    return this._rootUrl || this.config.rootUrl;
  }
  /**
   * Sets the root URL for API operations in this service.
   */
  set rootUrl(rootUrl) {
    this._rootUrl = rootUrl;
  }
  /**
   * Creates a new `HttpParams` with the correct codec
   */
  newParams() {
    return new HttpParams({
      encoder: PARAMETER_CODEC$1
    });
  }
};

/* tslint:disable */
/**
 * Global configuration for AviatarApi services
 */
class AviatarApiConfiguration {
  constructor() {
    this.rootUrl = 'https://api-v1.apps.aviatar.io';
  }
  static {
    this.ɵfac = function AviatarApiConfiguration_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AviatarApiConfiguration)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AviatarApiConfiguration,
      factory: AviatarApiConfiguration.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AviatarApiConfiguration, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/* tslint:disable */
class SubjectService extends BaseService$1 {
  static {
    this.deprecatedResolveSubjectEndpointPath = '/subject-endpoints/{stage}/{subject}/{trackingId}';
  }
  static {
    this.resolveSubjectEndpointPath = '/subject-endpoints/{subject}/{trackingId}';
  }
  constructor(config, http) {
    super(config, http);
  }
  /**
   * Resolves the URL the subject with given type and tracking id.
   *
   * Resolves the URL the subject with given type and tracking id.
   * @param params The `SubjectService.DeprecatedResolveSubjectEndpointParams` containing the following parameters:
   *
   * - `trackingId`: Tracking id of the subject
   *
   * - `subject`: Subject type
   *
   * - `stage`: Stage of the application
   *
   * - `app_key`: Application key
   *
   * - `app_id`: Application id
   *
   * @return Successful operation.
   */
  deprecatedResolveSubjectEndpointResponse(params) {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body = null;
    if (params.appKey != null) __headers = __headers.set('app_key', params.appKey.toString());
    if (params.appId != null) __headers = __headers.set('app_id', params.appId.toString());
    let req = new HttpRequest('GET', this.rootUrl + `/subject-endpoints/${encodeURIComponent(String(params.stage))}/${encodeURIComponent(String(params.subject))}/${encodeURIComponent(String(params.trackingId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });
    return this.http.request(req).pipe(filter(_r => _r instanceof HttpResponse), map(_r => {
      return _r;
    }));
  }
  /**
   * Resolves the URL the subject with given type and tracking id.
   *
   * Resolves the URL the subject with given type and tracking id.
   * @param params The `SubjectService.DeprecatedResolveSubjectEndpointParams` containing the following parameters:
   *
   * - `trackingId`: Tracking id of the subject
   *
   * - `subject`: Subject type
   *
   * - `stage`: Stage of the application
   *
   * - `app_key`: Application key
   *
   * - `app_id`: Application id
   *
   * @return Successful operation.
   */
  deprecatedResolveSubjectEndpoint(params) {
    return this.deprecatedResolveSubjectEndpointResponse(params).pipe(map(_r => _r.body));
  }
  /**
   * Resolves the URL the subject with given type and tracking id.
   *
   * Resolves the URL the subject with given type and tracking id.
   * @param params The `SubjectService.ResolveSubjectEndpointParams` containing the following parameters:
   *
   * - `trackingId`: Tracking id of the subject
   *
   * - `subject`: Subject type
   *
   * - `app_id`: Application id
   *
   * @return Successful operation.
   */
  resolveSubjectEndpointResponse(params) {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body = null;
    if (params.appId != null) __headers = __headers.set('app_id', params.appId.toString());
    let req = new HttpRequest('GET', this.rootUrl + `/subject-endpoints/${encodeURIComponent(String(params.subject))}/${encodeURIComponent(String(params.trackingId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });
    return this.http.request(req).pipe(filter(_r => _r instanceof HttpResponse), map(_r => {
      return _r;
    }));
  }
  /**
   * Resolves the URL the subject with given type and tracking id.
   *
   * Resolves the URL the subject with given type and tracking id.
   * @param params The `SubjectService.ResolveSubjectEndpointParams` containing the following parameters:
   *
   * - `trackingId`: Tracking id of the subject
   *
   * - `subject`: Subject type
   *
   * - `app_id`: Application id
   *
   * @return Successful operation.
   */
  resolveSubjectEndpoint(params) {
    return this.resolveSubjectEndpointResponse(params).pipe(map(_r => _r.body));
  }
  static {
    this.ɵfac = function SubjectService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SubjectService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SubjectService,
      factory: SubjectService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SubjectService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/**
 * Custom parameter codec to correctly handle the plus sign in parameter
 * values. See https://github.com/angular/angular/issues/18261
 */
class ParameterCodec {
  encodeKey(key) {
    return encodeURIComponent(key);
  }
  encodeValue(value) {
    return encodeURIComponent(value);
  }
  decodeKey(key) {
    return decodeURIComponent(key);
  }
  decodeValue(value) {
    return decodeURIComponent(value);
  }
}
const PARAMETER_CODEC = new ParameterCodec();
/**
 * Base class for API services
 */
class BaseService {
  constructor(config, http) {
    this.config = config;
    this.http = http;
    this._rootUrl = '';
  }
  /**
   * Returns the root url for API operations. If not set directly in this
   * service, will fallback to ApiConfiguration.rootUrl.
   */
  get rootUrl() {
    return this._rootUrl || this.config.rootUrl;
  }
  /**
   * Sets the root URL for API operations in this service.
   */
  set rootUrl(rootUrl) {
    this._rootUrl = rootUrl;
  }
  /**
   * Creates a new `HttpParams` with the correct codec
   */
  newParams() {
    return new HttpParams({
      encoder: PARAMETER_CODEC
    });
  }
}

/* tslint:disable */
/**
 * Global configuration for TasklistApi services
 */
class TasklistApiConfiguration {
  constructor() {
    this.rootUrl = '';
  }
  static {
    this.ɵfac = function TasklistApiConfiguration_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TasklistApiConfiguration)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TasklistApiConfiguration,
      factory: TasklistApiConfiguration.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TasklistApiConfiguration, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/* tslint:disable */
/**
 * Operations tasks.
 */
class TaskService extends BaseService {
  static {
    this.getTasksPath = '/task';
  }
  static {
    this.getTaskStreamPath = '/task-stream';
  }
  static {
    this.getTaskByIdPath = '/task/{id}';
  }
  constructor(config, http) {
    super(config, http);
  }
  /**
   * Lists tasks.
   * @param params The `TaskService.GetTasksParams` containing the following parameters:
   *
   * - `sort`: A collection of sort directives in the format +prop1.
   *
   * - `size`: The page size requested (defaults to 20)
   *
   * - `page`: The page number to access (0 indexed, defaults to 0)
   *
   * - `includeDeferred`: To request also tasks that are deferred.
   *
   * - `businessKey`: Filter for tasks with equal business key.
   *
   * @return Successful operation.
   */
  getTasksResponse(params) {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.includeDeferred != null) __params = __params.set('includeDeferred', params.includeDeferred.toString());
    if (params.businessKey != null) __params = __params.set('businessKey', params.businessKey.toString());
    let req = new HttpRequest('GET', this.rootUrl + `/task`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });
    return this.http.request(req).pipe(filter(_r => _r instanceof HttpResponse), map(_r => {
      return _r;
    }));
  }
  /**
   * Lists tasks.
   * @param params The `TaskService.GetTasksParams` containing the following parameters:
   *
   * - `sort`: A collection of sort directives in the format +prop1.
   *
   * - `size`: The page size requested (defaults to 20)
   *
   * - `page`: The page number to access (0 indexed, defaults to 0)
   *
   * - `includeDeferred`: To request also tasks that are deferred.
   *
   * - `businessKey`: Filter for tasks with equal business key.
   *
   * @return Successful operation.
   */
  getTasks(params) {
    return this.getTasksResponse(params).pipe(map(_r => _r.body));
  }
  /**
   * Stream of user tasks.
   * @return Successful operation.
   */
  getTaskStreamResponse() {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body = null;
    let req = new HttpRequest('GET', this.rootUrl + `/task-stream`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });
    return this.http.request(req).pipe(filter(_r => _r instanceof HttpResponse), map(_r => {
      return _r;
    }));
  }
  /**
   * Stream of user tasks.
   * @return Successful operation.
   */
  getTaskStream() {
    return this.getTaskStreamResponse().pipe(map(_r => _r.body));
  }
  /**
   * Lists single task by id.
   * @param id Task id.
   * @return Successful operation.
   */
  getTaskByIdResponse(id) {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body = null;
    let req = new HttpRequest('GET', this.rootUrl + `/task/${encodeURIComponent(String(id))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });
    return this.http.request(req).pipe(filter(_r => _r instanceof HttpResponse), map(_r => {
      return _r;
    }));
  }
  /**
   * Lists single task by id.
   * @param id Task id.
   * @return Successful operation.
   */
  getTaskById(id) {
    return this.getTaskByIdResponse(id).pipe(map(_r => _r.body));
  }
  static {
    this.ɵfac = function TaskService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TaskService)(i0.ɵɵinject(TasklistApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TaskService,
      factory: TaskService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TaskService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: TasklistApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
class ProcessService extends BaseService$1 {
  static {
    this.getProcessStartersPath = '/process-starters';
  }
  static {
    this.getProcessEnginesPath = '/process-engines';
  }
  constructor(config, http) {
    super(config, http);
  }
  /**
   * Returns all or filtered process starters.
   * @param params The `ProcessService.GetProcessStartersParams` containing the following parameters:
   *
   * - `app_id`: Caller's Application id
   *
   * - `offset`: Paginate the result collection with the given offset.
   *
   * - `limit`: Limits the result collection.
   *
   * - `id`: Filter the result collection by providing one or more process ids.
   *
   * @return Successful operation.
   */
  getProcessStartersResponse(params) {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body = null;
    if (params.appId != null) __headers = __headers.set('app_id', params.appId.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    (params.id || []).forEach(val => {
      if (val != null) __params = __params.append('id', val.toString());
    });
    let req = new HttpRequest('GET', this.rootUrl + `/process-starters`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });
    return this.http.request(req).pipe(filter(_r => _r instanceof HttpResponse), map(_r => {
      return _r;
    }));
  }
  /**
   * Returns all or filtered process starters.
   * @param params The `ProcessService.GetProcessStartersParams` containing the following parameters:
   *
   * - `app_id`: Caller's Application id
   *
   * - `offset`: Paginate the result collection with the given offset.
   *
   * - `limit`: Limits the result collection.
   *
   * - `id`: Filter the result collection by providing one or more process ids.
   *
   * @return Successful operation.
   */
  getProcessStarters(params) {
    return this.getProcessStartersResponse(params).pipe(map(_r => _r.body));
  }
  /**
   * Returns all or filtered process engines.
   * @param params The `ProcessService.GetProcessEnginesParams` containing the following parameters:
   *
   * - `app_id`: Caller's Application id
   *
   * - `offset`: Paginate the result collection with the given offset.
   *
   * - `limit`: Limits the result collection.
   *
   * - `id`: Filter the result collection by providing one or more process engine ids.
   *
   * @return Successful operation.
   */
  getProcessEnginesResponse(params) {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body = null;
    if (params.appId != null) __headers = __headers.set('app_id', params.appId.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    (params.id || []).forEach(val => {
      if (val != null) __params = __params.append('id', val.toString());
    });
    let req = new HttpRequest('GET', this.rootUrl + `/process-engines`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });
    return this.http.request(req).pipe(filter(_r => _r instanceof HttpResponse), map(_r => {
      return _r;
    }));
  }
  /**
   * Returns all or filtered process engines.
   * @param params The `ProcessService.GetProcessEnginesParams` containing the following parameters:
   *
   * - `app_id`: Caller's Application id
   *
   * - `offset`: Paginate the result collection with the given offset.
   *
   * - `limit`: Limits the result collection.
   *
   * - `id`: Filter the result collection by providing one or more process engine ids.
   *
   * @return Successful operation.
   */
  getProcessEngines(params) {
    return this.getProcessEnginesResponse(params).pipe(map(_r => _r.body));
  }
  static {
    this.ɵfac = function ProcessService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ProcessService)(i0.ɵɵinject(AviatarApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ProcessService,
      factory: ProcessService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProcessService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AviatarApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();
class TaskFormComponent {
  constructor(subjectService, taskService, keycloakService, apiProcessService) {
    this.subjectService = subjectService;
    this.taskService = taskService;
    this.keycloakService = keycloakService;
    this.apiProcessService = apiProcessService;
    this.taskCompleted = new EventEmitter();
    this.authTokenSubject = new BehaviorSubject(null);
    this.getElementUrl = task => {
      return this.subjectService.resolveSubjectEndpoint({
        appId: '',
        trackingId: task.id,
        subject: task.customForm.subject
      }).pipe(filter(subjects => subjects.length > 0), map(subjects => subjects[0].endpointUrl), map(this.removeTrackingQueryParamFromUrl));
    };
    this.getProcessEngineBaseUrl = task => {
      return this.apiProcessService.getProcessEngines({
        appId: '',
        id: [task.processEngineId]
      }).pipe(filter(engines => engines.length > 0), map(engines => engines[0].engineBaseUrl));
    };
    this.removeTrackingQueryParamFromUrl = subjectEndpointUrl => {
      const url = new URL(subjectEndpointUrl);
      return subjectEndpointUrl.replace(url.search, '');
    };
  }
  ngOnInit() {
    this.task$ = this.taskService.getTaskById(this.taskId).pipe(shareReplay());
    this.engineBaseUrl$ = this.task$.pipe(switchMap(this.getProcessEngineBaseUrl));
    this.bearerToken$ = this.authTokenSubject.pipe(
    // provides valid token for requests from form to API
    flatMap(() => from(this.keycloakService.getToken())));
    this.elementConfig$ = this.task$.pipe(filter(task => !!task), mergeMap(task => combineLatest([of(task), this.getElementUrl(task)])), map(([task, elementUrl]) => ({
      tag: task.customForm.elementName,
      url: elementUrl
    })));
  }
  onTaskCompleted(message) {
    this.taskCompleted.emit(message);
  }
  static {
    this.ɵfac = function TaskFormComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TaskFormComponent)(i0.ɵɵdirectiveInject(SubjectService), i0.ɵɵdirectiveInject(TaskService), i0.ɵɵdirectiveInject(i1.KeycloakService), i0.ɵɵdirectiveInject(ProcessService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TaskFormComponent,
      selectors: [["aviatar-process-task-form"]],
      inputs: {
        taskId: "taskId",
        payload: "payload"
      },
      outputs: {
        taskCompleted: "taskCompleted"
      },
      standalone: false,
      decls: 7,
      vars: 10,
      consts: [["loading", ""], ["error", ""], [4, "ngIf", "ngIfElse"], [3, "task", "engineUrl", "bearerToken$", "payload", "completed", 4, "axLazyElementDynamic", "axLazyElementDynamicUrl", "axLazyElementDynamicErrorTemplate"], [3, "completed", "task", "engineUrl", "bearerToken$", "payload"], [1, "alert", "alert-danger"]],
      template: function TaskFormComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, TaskFormComponent_ng_container_0_Template, 4, 3, "ng-container", 2);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵpipe(2, "async");
          i0.ɵɵpipe(3, "async");
          i0.ɵɵpipe(4, "async");
          i0.ɵɵtemplate(5, TaskFormComponent_ng_template_5_Template, 1, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          const loading_r5 = i0.ɵɵreference(6);
          i0.ɵɵproperty("ngIf", !!i0.ɵɵpipeBind1(1, 2, ctx.bearerToken$) && !!i0.ɵɵpipeBind1(2, 4, ctx.engineBaseUrl$) && !!i0.ɵɵpipeBind1(3, 6, ctx.task$) && i0.ɵɵpipeBind1(4, 8, ctx.elementConfig$))("ngIfElse", loading_r5);
        }
      },
      dependencies: [i5.NgIf, i6.LazyElementDynamicDirective, i5.AsyncPipe],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TaskFormComponent, [{
    type: Component,
    args: [{
      selector: 'aviatar-process-task-form',
      template: "<ng-container *ngIf=\"!!(bearerToken$ | async) && !!(engineBaseUrl$ | async) && !!(task$ | async) && (elementConfig$ | async) as config; else loading\">\n\n  <ax-lazy-element\n          *axLazyElementDynamic=\"config.tag; url: config.url; errorTemplate: error\"\n          [task]=\"task$ | async\"\n          [engineUrl]=\"engineBaseUrl$ | async\"\n          [bearerToken$]=\"bearerToken$\"\n          [payload]=\"payload\"\n          (completed)=\"onTaskCompleted($event)\"\n  ></ax-lazy-element>\n\n  <ng-template #error>\n    <div class=\"alert alert-danger\">\n      Could not load task form component.\n    </div>\n  </ng-template>\n</ng-container>\n\n<ng-template #loading>\n  Loading task form...\n</ng-template>"
    }]
  }], () => [{
    type: SubjectService
  }, {
    type: TaskService
  }, {
    type: i1.KeycloakService
  }, {
    type: ProcessService
  }], {
    taskId: [{
      type: Input
    }],
    payload: [{
      type: Input
    }],
    taskCompleted: [{
      type: Output
    }]
  });
})();

/* tslint:disable */
class NotificationQueryService extends BaseService {
  static {
    this.searchPath = '/notifications';
  }
  constructor(config, http) {
    super(config, http);
  }
  /**
   * Returns all notifications matching the given criteria
   * @param params The `NotificationQueryService.SearchParams` containing the following parameters:
   *
   * - `type`: The type of the notification
   *
   * - `state`: The state of the notification
   *
   * - `severity`: The severity of the notification
   *
   * - `offset`: Index of first result.
   *
   * - `limit`: Number of maximum results.
   *
   * - `aircraftId`: The aircraft id.
   *
   * @return If the request completed successfully.
   */
  searchResponse(params) {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.state != null) __params = __params.set('state', params.state.toString());
    if (params.severity != null) __params = __params.set('severity', params.severity.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    (params.aircraftId || []).forEach(val => {
      if (val != null) __params = __params.append('aircraftId', val.toString());
    });
    let req = new HttpRequest('GET', this.rootUrl + `/notifications`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });
    return this.http.request(req).pipe(filter(_r => _r instanceof HttpResponse), map(_r => {
      return _r;
    }));
  }
  /**
   * Returns all notifications matching the given criteria
   * @param params The `NotificationQueryService.SearchParams` containing the following parameters:
   *
   * - `type`: The type of the notification
   *
   * - `state`: The state of the notification
   *
   * - `severity`: The severity of the notification
   *
   * - `offset`: Index of first result.
   *
   * - `limit`: Number of maximum results.
   *
   * - `aircraftId`: The aircraft id.
   *
   * @return If the request completed successfully.
   */
  search(params) {
    return this.searchResponse(params).pipe(map(_r => _r.body));
  }
  static {
    this.ɵfac = function NotificationQueryService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NotificationQueryService)(i0.ɵɵinject(TasklistApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NotificationQueryService,
      factory: NotificationQueryService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotificationQueryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: TasklistApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/**
 * Data entries.
 */
class DataEntryService extends BaseService {
  static {
    this.getDataEntriesPath = '/data-entry';
  }
  constructor(config, http) {
    super(config, http);
  }
  /**
   * Lists data entries.
   * @param params The `DataEntryService.GetDataEntriesParams` containing the following parameters:
   *
   * - `sort`: A collection of sort directives in the format +prop1.
   *
   * - `size`: The page size requested (defaults to 20)
   *
   * - `page`: The page number to access (0 indexed, defaults to 0)
   *
   * @return Successful operation.
   */
  getDataEntriesResponse(params) {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest('GET', this.rootUrl + `/data-entry`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });
    return this.http.request(req).pipe(filter(_r => _r instanceof HttpResponse), map(_r => {
      return _r;
    }));
  }
  /**
   * Lists data entries.
   * @param params The `DataEntryService.GetDataEntriesParams` containing the following parameters:
   *
   * - `sort`: A collection of sort directives in the format +prop1.
   *
   * - `size`: The page size requested (defaults to 20)
   *
   * - `page`: The page number to access (0 indexed, defaults to 0)
   *
   * @return Successful operation.
   */
  getDataEntries(params) {
    return this.getDataEntriesResponse(params).pipe(map(_r => _r.body));
  }
  static {
    this.ɵfac = function DataEntryService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DataEntryService)(i0.ɵɵinject(TasklistApiConfiguration), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DataEntryService,
      factory: DataEntryService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DataEntryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: TasklistApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/**
 * Provider for all TasklistApi services, plus TasklistApiConfiguration
 */
class TasklistApiModule {
  static forRoot(customParams) {
    return {
      ngModule: TasklistApiModule,
      providers: [{
        provide: TasklistApiConfiguration,
        useValue: {
          rootUrl: customParams.rootUrl
        }
      }]
    };
  }
  static {
    this.ɵfac = function TasklistApiModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TasklistApiModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TasklistApiModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [TasklistApiConfiguration, TaskService, NotificationQueryService, DataEntryService],
      imports: [HttpClientModule, HttpClientModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TasklistApiModule, [{
    type: NgModule,
    args: [{
      imports: [HttpClientModule],
      exports: [HttpClientModule],
      declarations: [],
      providers: [TasklistApiConfiguration, TaskService, NotificationQueryService, DataEntryService]
    }]
  }], null, null);
})();
class CustomKeycloakInterceptor {
  constructor(keycloakService) {
    this.keycloakService = keycloakService;
  }
  intercept(request, next) {
    // todo check whether url is a known process engine url
    // in addition to the default keycloak interceptor provided by @aviatar/sso module
    // this interceptor checks if the request URL is a process engine
    // if (!this.keycloakService.inBearerWhitelist(request.url) &&
    //   !this.processEngineResolver.getProcessEngines().find(engine => request.url.startsWith(engine.engineBaseUrl))) {
    //   return next.handle(request);
    // }
    return from(this.keycloakService.getToken()).pipe(mergeMap(token => {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
      return next.handle(request);
    }));
  }
  static {
    this.ɵfac = function CustomKeycloakInterceptor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CustomKeycloakInterceptor)(i0.ɵɵinject(i1.KeycloakService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CustomKeycloakInterceptor,
      factory: CustomKeycloakInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CustomKeycloakInterceptor, [{
    type: Injectable
  }], () => [{
    type: i1.KeycloakService
  }], null);
})();
class ApiGatewayInterceptor {
  constructor(keycloakService, processModuleConfig) {
    this.keycloakService = keycloakService;
    this.processModuleConfig = processModuleConfig;
  }
  intercept(request, next) {
    if (!request.url.startsWith(this.processModuleConfig.aviatarApi.baseUrl)) {
      return next.handle(request);
    }
    return from(this.keycloakService.getToken()).pipe(mergeMap(token => {
      request = request.clone({
        setHeaders: {
          app_id: `${this.processModuleConfig.aviatarApi.appId}`,
          app_key: `${this.processModuleConfig.aviatarApi.appKey}`
        }
      });
      return next.handle(request);
    }));
  }
  static {
    this.ɵfac = function ApiGatewayInterceptor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ApiGatewayInterceptor)(i0.ɵɵinject(i1.KeycloakService), i0.ɵɵinject(PROCESS_MODULE_CONFIG));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ApiGatewayInterceptor,
      factory: ApiGatewayInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiGatewayInterceptor, [{
    type: Injectable
  }], () => [{
    type: i1.KeycloakService
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PROCESS_MODULE_CONFIG]
    }]
  }], null);
})();

/* tslint:disable */
/**
 * Provider for all AviatarApi services, plus AviatarApiConfiguration
 */
class AviatarApiModule {
  static forRoot(customParams) {
    return {
      ngModule: AviatarApiModule,
      providers: [{
        provide: AviatarApiConfiguration,
        useValue: {
          rootUrl: customParams.rootUrl
        }
      }]
    };
  }
  static {
    this.ɵfac = function AviatarApiModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AviatarApiModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AviatarApiModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [AviatarApiConfiguration, SubjectService, ProcessService],
      imports: [HttpClientModule, HttpClientModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AviatarApiModule, [{
    type: NgModule,
    args: [{
      imports: [HttpClientModule],
      exports: [HttpClientModule],
      declarations: [],
      providers: [AviatarApiConfiguration, SubjectService, ProcessService]
    }]
  }], null, null);
})();
class AviatarApiConfigClass extends AviatarApiConfiguration {
  constructor(processModuleConfig) {
    super();
    this.processModuleConfig = processModuleConfig;
    this.rootUrl = processModuleConfig.aviatarApi.baseUrl;
  }
  static {
    this.ɵfac = function AviatarApiConfigClass_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AviatarApiConfigClass)(i0.ɵɵinject(PROCESS_MODULE_CONFIG));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AviatarApiConfigClass,
      factory: AviatarApiConfigClass.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AviatarApiConfigClass, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PROCESS_MODULE_CONFIG]
    }]
  }], null);
})();
class TasklistApiConfigClass extends TasklistApiConfiguration {
  constructor(processModuleConfig) {
    super();
    this.processModuleConfig = processModuleConfig;
    this.rootUrl = processModuleConfig.tasklistApiBaseUrl;
  }
  static {
    this.ɵfac = function TasklistApiConfigClass_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TasklistApiConfigClass)(i0.ɵɵinject(PROCESS_MODULE_CONFIG));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TasklistApiConfigClass,
      factory: TasklistApiConfigClass.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TasklistApiConfigClass, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PROCESS_MODULE_CONFIG]
    }]
  }], null);
})();
// @dynamic see https://github.com/ng-packagr/ng-packagr/issues/641
class ProcessModule {
  static {
    this.ɵfac = function ProcessModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ProcessModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ProcessModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        provide: TasklistApiConfiguration,
        useClass: TasklistApiConfigClass
      }, {
        provide: HTTP_INTERCEPTORS,
        useClass: CustomKeycloakInterceptor,
        multi: true
      }, {
        provide: HTTP_INTERCEPTORS,
        useClass: ApiGatewayInterceptor,
        multi: true
      }, {
        provide: AviatarApiConfiguration,
        // configure rest client base path
        useClass: AviatarApiConfigClass
      }],
      imports: [CommonModule, TasklistApiModule, AviatarApiModule, LazyElementsModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProcessModule, [{
    type: NgModule,
    args: [{
      declarations: [TaskFormComponent],
      imports: [CommonModule, TasklistApiModule, AviatarApiModule, LazyElementsModule],
      exports: [TaskFormComponent],
      providers: [{
        provide: TasklistApiConfiguration,
        useClass: TasklistApiConfigClass
      }, {
        provide: HTTP_INTERCEPTORS,
        useClass: CustomKeycloakInterceptor,
        multi: true
      }, {
        provide: HTTP_INTERCEPTORS,
        useClass: ApiGatewayInterceptor,
        multi: true
      }, {
        provide: AviatarApiConfiguration,
        // configure rest client base path
        useClass: AviatarApiConfigClass
      }]
    }]
  }], null, null);
})();

/*
 * Public API Surface of process
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AviatarApiConfigClass, AviatarApiModule, PROCESS_MODULE_CONFIG, ProcessModule, ProcessService$1 as ProcessService, TaskFormComponent, TasklistApiConfigClass, TasklistApiModule };
